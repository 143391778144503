<!-- eslint-disable prettier/prettier -->
<template>
  <div id="header-nav" class="primary-navbar flex justify-between items-center fixed px-10 bg-white/75 w-full z-10">
    <div class="h-20 w-24">
         <img width="100%" :src="require('@/assets/logo.png')" alt="green tulip" class="object-cover">
    </div>

    <ul class="hidden lg:flex gap-10 h-9">
      <router-link class="hover:text-blue-400 cursor-pointer p-2 font-bold tracking-wider text-sm" to="/about-page">ABOUT</router-link>
      <!-- <router-link class="hover:text-blue-400 cursor-pointer p-2 font-bold tracking-wider text-sm" to="/services-page">SERVICES</router-link> -->
      <router-link class="hover:text-blue-400 cursor-pointer p-2 font-bold tracking-wider text-sm" to="/services">SERVICES</router-link>
      <router-link class="hover:text-blue-400 cursor-pointer p-2 font-bold tracking-wider text-sm" to="/contact-page">CONTACT</router-link>
    </ul>

    <ul class="gap-5 h-9 hidden lg:flex">
      <li class="hover:text-blue-400 cursor-pointer p-2"><a href="https://www.facebook.com/GreenTulipGardens" target="_blank"><span class="mdi mdi-facebook text-2xl"></span></a></li>
      <!-- <li class="hover:text-blue-400 cursor-pointer p-2"><span class="mdi mdi-twitter text-2xl"></span></li> -->
      <li class="hover:text-blue-400 cursor-pointer p-2"><a href="https://www.instagram.com/greentulip_gardens/" target="_blank"><span class="mdi mdi-instagram text-2xl"></span></a></li>
    </ul>

    <div class="hamburger-menu lg:hidden" @click="openNav">
      <v-btn icon><span class="mdi mdi-menu text-3xl"></span></v-btn>
    </div>

    <section
    id="mobile-nav"
    class="h-screen w-0 fixed top-0 right-0 overflow-x-hidden z-10 transition ease duration-500 bg-white/95">
      <div class="h-9 mb-10"></div>  
    <div class="nav-links flex flex-col justify-center items-center gap-4">
        <div class="hamburger-menu lg:hidden mb-16" @click="closeNav">
          <v-btn icon><span class="mdi mdi-close text-5xl"></span></v-btn>
        </div>
        <router-link to="/about-page" active-class="active" class="text-2xl  font-semibold"
          >About Us</router-link
        >
        <router-link to="/services" active-class="active" class="text-2xl  font-semibold"
          >Services</router-link
        >
        <router-link to="/contact-page" active-class="active" class="text-2xl  font-semibold"
          >Contact Us</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
    };
  },

  mounted() {
    var prevScrollPos = window.pageYOffset;

    window.onscroll = () => {
      var currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        let nav = document.getElementById("header-nav");
        nav.style.top = "0";
      } else {
        let nav = document.getElementById("header-nav");
        nav.style.top = "-100px";
      }

      prevScrollPos = currentScrollPos;
    };
  },

  methods: {
    openNav() {
      let nav = document.getElementById("mobile-nav");
      nav.style.width = "100%";
    },

    closeNav() {
      let nav = document.getElementById("mobile-nav");
      nav.style.width = "0";
    },
  },
};
</script>

<style scoped lang="scss">
#header-nav {
  transition: top 0.5s ease;
}

#mobile-nav {
  transition: width 0.5s ease-in-out;
}
</style>

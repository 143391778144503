<template>
  <header
    class="banner-div bg-cover"
    style="background-size: cover"
    :style="{ 'background-image': 'url(' + imageUrl + ')' }"
  >
    <div
      class="banner-contents flex flex-col justify-center items-center gap-3 bg-black/50 p-20 h-[60vh]"
    >
      <p class="font-bold text-3xl text-white tracking-widest">{{ title }}</p>
      <div class="links flex gap-3 text-white font-semibold">
        <p
          class="hover:cursor-pointer tracking-widest"
          @click="$router.push('/')"
        >
          Home
        </p>
        <span class="mdi mdi-chevron-right text-xl text-cyan-400"></span>
        <p class="text-slate-300 tracking-widest">{{ title }}</p>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["title", "imageUrl"],
};
</script>

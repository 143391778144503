import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import SecondaryAppbar from "@/components/secondary-navbar.vue";
import AmenitiesCmp from "@/components/amenities-component.vue";
import PrimaryFooter from "@/components/primary-footer.vue";
import Banner from "@/components/banner-component.vue";

import "./styles.css";

createApp(App)
  .use(router)
  .component("secondary-appbar", SecondaryAppbar)
  .component("primary-footer", PrimaryFooter)
  .component("amenities", AmenitiesCmp)
  .component("banner-div", Banner)
  .mount("#app");

<template>
  <div class="home">
    <main>
      <header class="banner-div bg-cover w-screen">
        <primary-nav />

        <div
          class="banner-contents flex max-lg:flex-col justify-center items-center gap-20 bg-black/50 px-3 lg:px-20 max-lg:pb-6 py-30 md:h-[100vh]"
        >
          <div
            class="information text-white text-start flex flex-col justify-center mt-28 lg:mt-0"
          >
            <p class="text-md font-semibold">WELCOME TO GREENTULIP GARDENS</p>
            <br />
            <p class="max-md:text-3xl lg:text-6xl font-bold">
              THE GREATEST <br />
              AMUSEMENT PARK
            </p>
            <br />
            <p>
              Dedicated to providing families and friends with moments of joy
              and exhilaration
              <br />
              Explore our themed grounds, indulge in delicious african quisines
              from our lounge area, <br />
              and soak in the vibrant atmosphere that permeates every corner of
              our park
            </p>
          </div>

          <form
            id="booking-form"
            @submit.prevent="bookNow"
            class="form bg-white/50 p-10 flex flex-col gap-6 h-[60%] max-lg:w-[90%]"
          >
            <input
              class="w-full p-3 text-sm focus:outline-none"
              type="text"
              name="name"
              id="name"
              placeholder="Your Name"
            />
            <div class="grid grid-cols-2 gap-4">
              <input
                class="w-full p-3 text-sm focus:outline-none"
                type="text"
                name="phone"
                id="phone"
                placeholder="Your Phone"
              />
              <input
                class="w-full p-3 text-sm focus:outline-none"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email"
              />
            </div>
            <input
              class="w-full p-3 text-sm focus:outline-none"
              type="date"
              name="date"
              id="date"
              placeholder="DD-MM-YYYY"
            />
            <input
              class="w-full p-3 text-sm focus:outline-none"
              type="number"
              name="guest"
              id="guest"
              placeholder="Guest"
            />

            <button
              type="submit"
              class="bg-blue-900 w-32 text-white rounded-sm p-2"
            >
              BOOK NOW
            </button>

            <!-- <div class="btn bg-blue-900 w-32 text-white rounded-sm p-2">
              <p>BOOK NOW</p>
            </div> -->
          </form>
        </div>
      </header>

      <p class="text-2xl font-bold text-center max-sm:my-8 my-20">
        Activities Overview
      </p>

      <section
        class="activities md:w-5/6 mx-auto grid md:grid-cols-2 lg:grid-cols-4 md:justify-around gap-6 lg:mb-14"
      >
        <div
          v-for="(item, index) in activities"
          :key="index"
          class="card w-full max-sm:w-[95%] max-sm:rounded-lg mx-auto gap-3 h-96 bg-cover text-white"
          style="position: relative"
          :style="{ 'background-image': 'url(' + item.image + ')' }"
        >
          <div
            class="text-start h-full p-5 flex flex-col justify-end absolute bg-blue-900/25 max-sm:rounded-lg"
          >
            <p class="text-md font-semibold">{{ item.title }}</p>
            <p class="my-2 text-sm">{{ item.desc }}</p>
            <div
              class="btn hover:cursor-pointer font-semibold w-6/12"
              @click="$router.push('/services')"
            >
              READ MORE <span class="mdi mdi-arrow-right"></span>
            </div>
          </div>
        </div>
      </section>

      <amenities />

      <section
        class="packages flex max-lg:flex-col justify-center gap-9 md:w-5/6 max-sm:p-8 mx-auto my-10 items-center"
      >
        <div
          class="package-intro md:p-8 text-start lg:w-5/12 flex flex-col gap-4 justify-center"
        >
          <p class="text-sm text-cyan-400 font-bold">Accomodation Packages</p>
          <p class="text-3xl text-sky-950 font-bold">
            PACKAGES FOR THAT UNFORGETTABLE EXPERIENCE
          </p>
          <p>
            Each package is crafted to meet your unique needs and elevate your
            experience at GreenTulip Gardens.
          </p>
        </div>

        <div
          class="family-package flex flex-col items-center justify-center bg-sky-950 py-8 gap-5 w-full lg:w-4/12 max-sm:p-6"
        >
          <!-- <p class="text-xl font-bold text-white">Family Package</p> -->
          <p class="text-cyan-400 text-2xl font-semibold">
            Package Rates<span class="text-slate-300 text-sm">/person</span>
          </p>
          <div class="w-2/6 h-0.5 bg-gray-300"></div>
          <p class="text-white">
            <span class="mdi mdi-check-bold mr-1 text-cyan-400 text-md"></span
            >Person sharing including breakfast
          </p>
          <p class="text-white">
            <span class="mdi mdi-check-bold mr-1 text-cyan-400 text-md"></span
            >Single rates including breakfast
          </p>
          <p class="text-white">
            <span class="mdi mdi-check-bold mr-1 text-cyan-400 text-md"></span>
            person sharing without breakfast
          </p>
          <p class="text-white">
            <span class="mdi mdi-check-bold mr-1 text-cyan-400 text-md"></span>
            Single rates without breakfast
          </p>
          <div
            @click="scrollToForm"
            class="book-btn bg-cyan-400 text-center p-3 text-white font-bold w-32 hover:cursor-pointer"
          >
            Book Now
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PrimaryHeader from "@/components/primary-navbar.vue";

export default {
  name: "HomeView",

  components: {
    "primary-nav": PrimaryHeader,
  },

  data() {
    return {
      activities: [
        {
          title: "Wedding Grounds",
          desc: "The perfect setting for your special day amidst our enchanting wedding grounds",
          image: require("@/assets/images/image_6.jpg"),
        },
        {
          title: "Picnic & Parties",
          desc: "Organizing seasonal celebrations and holiday-themed activities",
          image: require("@/assets/images/lounge.jpeg"),
        },
        {
          title: "Photoshoot & Videography",
          desc: "Promote breathtaking viewpoints and scenic overlooks within the park",
          image: require("@/assets/images/image_7.jpg"),
        },
        {
          title: "Acommodation Options",
          desc: "Available accomodation within the park. Bed and Breakfast",
          image: require("@/assets/images/accomodation2.jpeg"),
        },
      ],
    };
  },

  methods: {
    bookNow() {
      alert("booking now");
    },

    scrollToForm() {
      const element = document.getElementById("booking-form");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.banner-div {
  background-image: url("@/assets/images/image_1.jpg");
}
</style>

<template>
  <section
    class="amenities-div flex max-lg:flex-col md:w-5/6 mx-auto md:gap-10"
  >
    <div class="md:bg-slate-100 flex flex-col lg:w-1/2 p-10">
      <div class="info grid md:grid-cols-2 md:gap-10">
        <div
          class="amenities bg-blue-200 p-7 w-2/8 rounded-md mb-4"
          v-for="(item, index) in amenities"
          :key="index"
        >
          <span class="text-8xl mdi" :class="item.icon"></span>
          <p class="font-semibold text-xl">{{ item.title }}</p>
          <p>{{ item.details }}</p>
        </div>
      </div>
      <div class="times mt-6">
        <div class="time flex justify-between md:px-10 py-3">
          <p>Monday - Friday</p>
          <p>
            <span class="mdi mdi-clock-time-eleven mr-2 text-blue-500"></span>
            07:00AM - 10:00PM
          </p>
        </div>
        <hr />
        <div class="time flex justify-between md:px-10 py-3">
          <p>Saturday - Sunday</p>
          <p>
            <span class="mdi mdi-clock-time-nine mr-2 text-blue-500"></span>
            07:00AM - 10:00PM
          </p>
        </div>
        <hr />
        <div class="time flex justify-between md:px-10 py-3">
          <p>Holiday</p>
          <p>
            <span class="mdi mdi-clock-time-nine mr-2 text-blue-500"></span>
            07:00AM - 10:00PM
          </p>
        </div>
      </div>
    </div>

    <div class="images relative lg:w-1/2">
      <img
        class="w-full h-full"
        :src="require('@/assets/images/image_4.jpg')"
        alt="image 4"
      />
      <div class="bg-white p-5 absolute w-2/4 left-0 bottom-0">
        <img :src="require('@/assets/images/room.jpeg')" alt="image 4" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      amenities: [
        {
          title: "Picturesque Beauty",
          details:
            "Capture unforgettable moments amidst our stunning surroundings.",
          icon: "mdi-beekeeper",
        },
        {
          title: "Wholesome Breakfast",
          details:
            "Start your day with our delightful array of fresh fruits, whole grains, and protein-rich options.",
          icon: "mdi-food-variant",
        },
        {
          title: "Team Building Activity",
          details:
            "Strengthen bonds and inspire collaboration with our exciting team-building activities",
          icon: "mdi-waterfall",
        },
      ],
    };
  },
};
</script>

<!-- eslint-disable prettier/prettier -->
<template>
  <div class="primary-footer md:p-10">
    <div class="flex max-lg:flex-col max-md:justify-center justify-between w-[90%] mx-auto">
        <div class="lg:text-start">
            <div class="w-[200px] max-sm:w-full mx-auto">
                <img width="100%" :src="require('@/assets/logo.png')" alt="green tulip" class="object-cover">
           </div>
           <div class="flex flex-col gap-2">
            <p class="font-semibold text-xl">Where every moment is an adventure!</p>
            <br />
            <p class="font-semibold"><span class="mdi mdi-map-marker  mr-2"></span>Nkubu, Meru County. Kenya</p>
            <p class="font-semibold"><span class="mdi mdi-mail mr-2"></span>info@greentulip.com</p>
            <p class="font-semibold"><span class="mdi mdi-phone  mr-2"></span>+254713298682 <span class="text-gray-500">or</span> +254781289226</p>
           </div>
        </div>

        <div class="flex max-lg:justify-between sm:justify-around lg:w-1/3 max-lg:my-9">
          <div class="quick-links lg:text-start flex flex-col gap-4">
            <p class="font-semibold text-xl">Quick Links</p>
            <div class="flex flex-col gap-2">
                <p class="hover:cursor-pointer"  @click="$router.push('/about-page')"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>About Us</p>
                <p class="hover:cursor-pointer" @click="$router.push('/services')"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Services</p>
                <p class="hover:cursor-pointer" @click="$router.push('/contact-page')"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Contact</p>
            </div>
          </div>

          <div class="useful-links text-start flex flex-col gap-4">
              <p class="font-semibold text-xl">Useful Links</p>
              <div class="flex flex-col gap-2">
                  <p class="hover:cursor-pointer"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Privacy Policy</p>
                  <p class="hover:cursor-pointer"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Terms and Conditions</p>
                  <p class="hover:cursor-pointer"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Disclaimer</p>
                  <p class="hover:cursor-pointer"><span class="mdi mdi-chevron-right mr-2 text-sky-950 text-xl"></span>Support</p>
              </div>
          </div>
        </div>

        <div class="times lg:w-[20%]">
            <p class="font-semibold text-xl max-lg:mb-5">Open Hours</p>
            <div class="time flex justify-between py-2 text-sm">
              <p>Weekday</p>
              <p>-</p>
              <p>
                <span
                  class="mdi mdi-clock-time-eleven mr-1 text-blue-500"
                ></span>
                07:00AM - 10:00PM
              </p>
            </div>
            <div class="time flex justify-between py-2  text-sm">
              <p>Weekend</p>
              <p>-</p>
              <p>
                <span class="mdi mdi-clock-time-nine mr-1 text-blue-500"></span>
                07:00AM - 10:00PM
              </p>
            </div>
            <div class="time flex justify-between py-2  text-sm">
              <p>Holiday</p>
              <p>-</p>
              <p>
                <span class="mdi mdi-clock-time-nine mr-1 text-blue-500"></span>
                07:00AM - 10:00PM
              </p>
            </div>
          </div>
    </div>
    <hr class="my-9">
    <div class="copyright text-gray-400 w-full flex justify-between max-sm:flex-col max-sm:gap-3 text-sm max-sm:pb-3">
      <p class=""><span class="text-cyan-400">GreenTulip Gardens</span> by <a class="underline text-black" href="https://mawira.netlify.app" target="_blank">Mawira Corp</a></p>
      <p>Copyright &copy; {{ currentYear }}. All rights reserved</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
